<template>
  <v-container>
    <h2 v-html="title"></h2>
    <v-row class="mb-8">
      <v-col v-for="item in items" :key="item.title" cols="12" md="4" class="d-flex mt-8">
        <div class="icon" v-html="item.icon"></div>
        <div class="content">
          <h3 class="mb-4" v-html="item.title"></h3>
          <span v-html="item.text"></span>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "iconic-list",
  props: [ 'items', 'title' ],
}
</script>

<style scoped>
.icon {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 15px;
  background-color: #f5f5f6;
  margin-right: 20px;
  font-family: TinkoffSans,sans-serif;
  font-size: 32px;
  line-height: 64px;
  text-align: center;
  color: rgba(0, 0, 0, .5);
}
</style>