<template>
  <v-app>
    <site-header :phone="phone"/>
    <top-banner :image="require('@/assets/images/top-banner.webp')" description="Начните инвестировать уже с 10 ₽">
      <h1 class="mt-8">Начните инвестировать<br> уже с 10 ₽</h1>
      <div class="my-8 col-md-6 pa-0">Покупайте акции, облигации, валюту и ETF.<br> Мгновенный вывод средств на карту.<br> Поддержка 24/7</div>
      <yellow-button @action="$vuetify.goTo('iframe')" class="d-block d-md-inline-block mx-auto mt-10">Открыть счет</yellow-button>
    </top-banner>

    <v-container>
      <iconic-list :title="iconicList.title" :items="iconicList.items"></iconic-list>
    </v-container>

    <app-form :url="form.url"/>

    <site-footer :phone="phone" :product-links="productLinks"/>
  </v-app>
</template>

<script>
import SiteHeader from '@/components/common/site-header';
import SiteFooter from '@/components/common/site-footer-v2';
import AppForm from '@/components/common/app-form';
import TopBanner from '@/components/common/top-banner';
import IconicList from '@/components/common/iconic-list';
import YellowButton from "@/components/common/yellow-button";

export default {
  name: 'App',
  components: {YellowButton, IconicList, TopBanner, SiteFooter, SiteHeader, AppForm},

  data: () => ({
    iconicList: {
      items: [
        {icon: '<img src="'+require('@/assets/images/985003f2-fcfd-4fd6-847c-83d3eb4f2991.svg')+'" alt="1">', title: 'Поможем выбрать,<br> куда вложить', text: 'Робот-советник и рекомендации аналитиков'},
        {icon: '<img src="'+require('@/assets/images/fc64b0c8-becc-4d90-a2f8-e7fa7e95e12c.svg')+'" alt="1">', title: 'Обслуживание от 0 ₽<br> в месяц', text: 'Комиссия от 0,025% за любые сделки'},
        {icon: '<img src="'+require('@/assets/images/b4cdad76-e92f-4c55-9ea0-491af6d95468.svg')+'" alt="1">', title: 'Круглосуточная<br> поддержка в чате', text: 'На сайте банка и в мобильном приложении'},
      ]
    },

    form: {
      url: 'https://www.tbank.ru/invest/iframe/form/account/',
    },

    productLinks: [
      {link: 'https://www.tbank.ru/about/documents/disclosure/', text: 'Регламент оказания услуг на рынке ценных бумаг'},
      {link: 'https://acdn.tinkoff.ru/static/documents/invest-tariff-fees.pdf', text: 'Тарифный план «Инвестор»'},
      {link: 'https://acdn.tinkoff.ru/static/documents/invest-tariff-trader.pdf', text: 'Тарифный план «Трейдер»'},
      {link: 'https://acdn.tinkoff.ru/static/documents/invest-tariff-premium.pdf', text: 'Тарифный план «Премиум»'},
    ],
    phone: process.env.VUE_APP_PHONE,
  }),
};
</script>

<style>

@font-face {
  font-family: 'TinkoffSans';
  src: url('assets/fonts/TinkoffSans.woff2');
}

@font-face {
  font-family: 'haas';
  src: url('assets/fonts/NeueHaasUnicaW1G-Regular.woff2');
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 400;
  font-display: block;
  src: url("assets/fonts/fa-regular-400.eot");
  src: url("assets/fonts/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-regular-400.woff2") format("woff2"), url("assets/fonts/fa-regular-400.woff") format("woff"), url("assets/fonts/fa-regular-400.ttf") format("truetype");
}

@font-face {
  font-family: 'Font Awesome';
  font-style: normal;
  font-weight: 900;
  font-display: block;
  src: url("assets/fonts/fa-solid-900.eot");
  src: url("assets/fonts/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("assets/fonts/fa-solid-900.woff2") format("woff2"), url("assets/fonts/fa-solid-900.woff") format("woff"), url("assets/fonts/fa-solid-900.ttf") format("truetype");
}

/*noinspection CssUnusedSymbol*/
.v-application {
  -webkit-font-smoothing: antialiased;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !important;
  font-feature-settings: "kern", "liga" 0, "clig" 0 !important;
  font-kerning: normal !important;
  font-size: 15px !important;
  font-stretch: 100% !important;
  text-rendering: optimizeLegibility;
  -webkit-text-size-adjust: 100%;
}

body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 400;
  font-size: 16px;
  font-family: haas, pragmatica, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif;
  color: #333333;
  font-kerning: normal;
  text-rendering: optimizeLegibility;
}

/*noinspection CssUnusedSymbol*/
.container {
  padding-top: 0;
  padding-bottom: 0;
}

@media (min-width: 1904px) {
  /*noinspection CssUnusedSymbol*/
  .container {
    max-width: 1185px;
  }
}

h1, h2, h3 {
  font-family: 'TinkoffSans', sans-serif;
  font-weight: 400;
}

h1 {
  text-align: center;
  font-size: 36px;
  line-height: 40px;
  font-weight: bold;
}

h2 {
  margin-top: 48px;
  margin-bottom: 24px;
  text-align: center;
  font-size: 36px;
  line-height: 40px;
}

h3 {
  font-size: 25px;
  line-height: 30px;
}

a {
  text-decoration: none;
  color: #1771e6;
}

a:hover {
  color: #1464cc;
}

.w100 {
  width: 100%;
}

::selection {
  background: rgba(255, 221, 45, .32) !important;
  color: rgba(0, 0, 0, .8) !important;
}

@media (min-width: 1904px) {
  .container {
    max-width: 1185px;
  }
}

@media screen and (max-width: 575px) {
}

</style>